import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { USERS_STORE } from '../../../store/modules/users';
import { DASHBOARD_ROUTE } from '../routes';
export default defineComponent({
    name: 'verify-withdrawal-page',
    data: () => {
        return {
            verifyForm: {
                code1: '',
                code2: '',
                code3: '',
                code4: '',
            },
            submitting: false,
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
    },
    methods: {
        ...mapActions(USERS_STORE, ['sendVerificationWithdrawal', 'verifyUserAccount']),
        inputAutoFocus(payload) {
            if (payload.index > 1 && payload.event.length) {
                this.$refs[`code${payload.index}`].focus();
            }
            else {
                this.$refs.code4.blur();
            }
        },
        resendCode() {
            this.sendVerificationWithdrawal(this.user.id)
                .then((response) => {
                if (response) {
                    this.$notify.success({
                        title: 'Success',
                        message: 'Instructions successfully sent to your email.',
                    });
                }
            });
        },
        onSubmit() {
            this.submitting = true;
            const { email, id } = this.user;
            const formData = {
                email,
                code: Object.values(this.verifyForm).join().replaceAll(',', ''),
            };
            this.verifyUserAccount({ formData, userId: id })
                .then((response) => {
                (this).$router.push({
                    name: DASHBOARD_ROUTE,
                    params: {
                        withdrawalVerified: response ? 1 : 0,
                        openWithdrawalForm: 1,
                        withdrawalDetails: this.$route.params.withdrawalDetails
                    }
                });
            })
                .catch(() => {
                this.$notify.error({
                    message: 'Verification code does not match.'
                });
            })
                .finally(() => {
                this.submitting = false;
            });
        },
    }
});
